<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :xxl="3" :xl="4">
            <a-form-item label="评论类型">
              <a-select v-model="search.type" placeholder="请选择" allowClear>
                <a-select-option value>全部</a-select-option>
                <a-select-option value="0">资讯</a-select-option>
                <a-select-option value="1">课程</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="3" :xl="4">
            <a-form-item label="审核状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear>
                <a-select-option value>全部</a-select-option>
                <a-select-option value="0">待审核</a-select-option>
                <a-select-option value="1">审核通过</a-select-option>
                <a-select-option value="2">审核拒绝</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div style="margin-bottom: 10px">
        <pxkeji-btn
          name="批量删除"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchDelete"
        />
        <pxkeji-btn
          name="批量通过"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(1)"
        />
        <pxkeji-btn
          name="批量拒绝"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(2)"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect">取消选择</a-button>
          </template>
        </span>
      </div>
      <div>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :rowSelection="{
            type: 'checkbox',
            onChange: onRowChange,
            selectedRowKeys: selectRows,
          }"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: list.tableHeight }"
        >
          <span slot="realName" slot-scope="realName">
            <span v-if="realName.realName">{{realName.realName}}</span>
            <span v-else>{{realName.nickName}}</span>
          </span>
          <span slot="tilte" slot-scope="tilte">
            <span v-if="tilte == '0'">资讯</span>
            <span v-if="tilte == '1'">课程</span>
          </span>
          <span slot="content" slot-scope="content">
            <span v-if="content.length>25">{{content.substr(0,25)+'...'}}</span>
            <span v-else>{{content}}</span>
          </span>
          <span slot="status" slot-scope="status">
            <a-tag v-if="status == '0'" color="blue">待审核</a-tag>
            <a-tag v-if="status == '1'" color="green">审核通过</a-tag>
            <a-tag v-if="status=='2'" color="red">审核拒绝</a-tag>
          </span>
          <span slot="action" slot-scope="action">
            <pxkeji-btn size="small" name="审核" @click="showInfo(action)" type="primary" />
            <span>
              <a-popconfirm @confirm="delInfo(action.comment.id)">
                <div slot="title">是否确认删除此评论吗？</div>
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn name="删除" size="small" type="danger" :loading="isloading" />
              </a-popconfirm>
            </span>
          </span>
        </a-table>
      </div>

      <a-modal
        :title="addedit.title"
        v-model="addedit.visible"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
        :afterClose="modalClose"
        width="1100px"
      >
        <CommentInfo @callbackMethod="save" :info="addedit.info" />
        <!-- <commentinfo @callbackMethod="save" :infos="addedit.info" /> -->
      </a-modal>
    </div>
  </div>
</template>
<script>
import CommentInfo from './commentInfo.vue'
const columns = [
  // {
  //   dataIndex: 'title',
  //   key: 'title',
  //   align: 'center',
  //   title: '标题',
  //   width: 200,
  // },
  {
    dataIndex: 'user',
    key: 'realName',
    align: 'center',
    title: '评论人',
    width: 200,
    scopedSlots: { customRender: 'realName' },
  },
  {
    // dataIndex: comment.source==1?'课程':'资讯',
    dataIndex: 'comment.source',
    key: 'tilte',
    align: 'center',
    title: '评论类型',
    width: 200,
    scopedSlots: { customRender: 'tilte' },
  },
  {
    dataIndex: 'comment.comment',
    key: 'content',
    align: 'center',
    title: '评论内容',
    // width: 300,
    scopedSlots: { customRender: 'content' },
  },
  {
    dataIndex: 'comment.addtime',
    key: 'comment.addtime',
    align: 'center',
    title: '发布时间',
    ellipsis: true,
    width: 300,
  },
  {
    dataIndex: 'comment.auditstatus',
    align: 'center',
    title: '状态',
    width: 200,
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'action',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]
let _this
export default {
  name: 'messageList',
  data() {
    return {
      addedit: {
        title: '',
        info: {},
        visible: false,
      },
      ids: '',
      search: {
        type: '',
        status: '',
      },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      selectRows: [], //选中项编号
      isloading: false,
      // addedit: {
      //   title: '',
      //   id: 0,
      //   visible: false,
      // },
    }
  },
  components: {
    CommentInfo,
  },
  created() {
    let _this = this
    _this.GetList()
  },
  mounted: function () {
    _this = this
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 252
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20
      } else {
        _this.list.pagination.pageSize = 10
      }
    }
    _this.GetList()
  },
  methods: {
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true
      _this.addedit.title = '审核'
      _this.addedit.info = info
      console.log(_this.addedit.info)
    },
    save() {
      console.log(23232656)
      _this.addedit.visible = false
      _this.GetList()
    },
    GetList() {
      let _this = this
      //获取列表
      _this.isloading = true
      console.log(_this.search.status)
      _this.$api.comment
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          '',
          _this.search.status,
          _this.search.type
        )
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.list.datas = res.data.records
            _this.list.pagination.total = Number(res.data.total)
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
          _this.modalClose()
          _this.cancelSelect()
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    searchList() {
      _this.list.pagination.current = 1
      _this.GetList()
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = ''
      }, 3000)
    },
    changeStatusBatch(status) {
      console.log(this.list.datas[0].comment.auditstatus)
      let msg = status == 1 ? '审核通过' : '审核拒绝'
      _this.$confirm({
        title: '提示',
        content: `您确定要${msg}已选择的评论吗？`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.updateStatusMore(_this.ids.join(','), status)
        },
        onCancel() {},
      })
    },
    /*批量审核通过审核拒绝 */
    updateStatusMore(ids, status) {
      _this.isloading = true
      _this.$api.comment
        .commentAuditBatch(ids, status)
        .then((res) => {
          console.log(res)
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg)
            _this.GetList()
          } else {
            _this.isloading = false
            _this.$message.error(res.errorMsg)
          }
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    /*审核 */
    updateStatus(ids, status) {
      _this.isloading = true
      _this.$api.comment
        .commentAudit(ids, status)
        .then((res) => {
          console.log(res)
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg)
            _this.GetList()
          } else {
            _this.isloading = false
            _this.$message.error(res.errorMsg)
          }
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current
      _this.list.pagination.pageSize = page.pageSize
      _this.GetList()
    },
    onRowChange(selectedRowKeys, selectedRows) {
      let _this = this
      var ids = []
      console.log(747747575)
      console.log(selectedRowKeys)
      console.log(selectedRows)
      selectedRows.forEach(function (item) {
        console.log(item)
        console.log(item.comment.id)
        ids.push(item.comment.id)
      })
      _this.ids = ids
      console.log(_this.ids)
      //table 多选
      _this.selectRows = selectedRowKeys
      console.log()
    },
    cancelSelect() {
      _this.selectRows = []
    },
    clearRows() {
      //清空选中项
      _this.selectRows = []
    },
    searchList() {
      _this.list.pagination.current = 1
      _this.GetList()
    },
    /** 批量删除 */
    batchDelete() {
      _this.$confirm({
        title: '提示',
        content: `您确定要删除已选择的评论吗？`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          // _this.delInfo(_this.selectRows.join(','))
          _this.$api.comment
            .commentDeleteBatch(_this.ids.join(','))
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg)
                _this.GetList()
              } else {
                _this.$message.error(res.errorMsg)
              }
              _this.isloading = false
            })
            .catch(() => {
              _this.isloading = false
            })
        },
        onCancel() {},
      })
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true
      _this.$api.comment
        .commentDelete(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg)
            _this.GetList()
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
  },
}
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.items {
  /* margin: 15px 0; */
  border-bottom: 1px #e8e8e8 solid;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.85);
}
.selectStyle {
  width: 200px !important;
  display: inline-block;
  margin-left: 5px;
}
.rechargeIpt {
  width: 120px;
  margin: 0 20px 0 5px;
}
.costIpt {
  width: 180px;
  margin: 0 20px 0 5px;
}
.templateItem {
  float: left;
  margin-left: 10px;
  margin-right: 20px;
}
.temBtn {
  cursor: pointer;
  color: #1890ff;
}
.itemBtns .ant-btn-primary {
  margin: 0 5px;
}
</style>

