<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="advZoneForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-form-model-item label="评论人">
            <a-input disabled v-model="info.user.realName" placeholder="请输入" :maxLength="50"></a-input>
          </a-form-model-item>
        </a-row>

        <a-col :span="24">
          <a-form-model-item label="评论类型">
            <a-radio-group disabled v-model="info.comment.source">
              <template>
                <a-radio :value="0">资讯</a-radio>
                <a-radio :value="1">课程</a-radio>
              </template>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-row>
          <a-form-model-item label="相关标题">
            <a-input disabled v-model="info.commentTitle" placeholder="请输入" :maxLength="50"></a-input>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="评论内容">
            <a-textarea
              style="height:130px"
              disabled
              v-model="info.comment.comment"
              placeholder="请输入"
              :maxLength="50"
            ></a-textarea>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="发布时间">
            <a-input disabled v-model="info.comment.addtime" placeholder="请输入" :maxLength="50"></a-input>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="审核" required="true" message="请选择资源类型">
            <a-radio-group
              required="true"
              @change="onChange"
              :defaultValue="info.comment.auditstatus=='2'?2:1"
            >
              <template>
                <a-radio :value="1">审核通过</a-radio>
                <a-radio :value="2">审核拒绝</a-radio>
              </template>
            </a-radio-group>
          </a-form-model-item>
        </a-row>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }" style="margin-top: 40px">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit(info.comment.id)" />
          <pxkeji-btn name="取消" type="default" @click="cancelClick" class="cancelBtn" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>
<script>
let _this
export default {
  name: 'advertzoneInfo',
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    // commentId: {
    //   type: String,
    //   // default: () => {},
    // },
  },
  // created(){

  // },
  // props: ['info'],
  // watch: {
  //   commentId(newVal) {
  //     console.log(newVal)
  //     _this.info = newVal
  //   },
  // },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value) {
        var reg = /^[0-9]*$/
        if (!reg.test(value)) {
          callback(new Error('请输入数字'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      // props:[""],
      // info: '',
      shenhe: 1,
      status: '1',
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules: {
        name: [
          { required: true, message: '请输入广告位名称', trigger: 'blur' },
        ],
      },
      // info: {
      //   width: 0,
      //   height: 0,
      //   status: true,
      // },
      isloading: false,
      loadingTip: '加载中...',
    }
  },
  mounted() {
    // console.log(_this.);
    _this = this
    console.log(_this.infos.id)
    // if (_this.infos.id) {
    //   _this.getInfo()
    // }
  },
  methods: {
    onChange(e) {
      console.log(e)
      if (e.target.value == 1) {
        _this.status = '1'
      } else {
        _this.status = '2'
      }
      console.log(_this.status)
    },
    /**取消 */
    cancelClick() {
      _this.$emit('callbackMethod')
    },
    /**数据回显 */
    getInfo() {
      _this.isloading = true
      _this.$api.adzone
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            let width = 0
            let height = 0
            if (res.data.size) {
              let size = res.data.size.split('X')
              width = Number(size[0])
              height = Number(size[1])
            }
            _this.info = {
              ...res.data,
              width: width,
              height: height,
              status: _this.$commen.changeIntBoolean(res.data.status),
            }
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    handleSubmit(id) {
      _this.isloading = true
      _this.loadingTip = '保存中...'
      _this.$api.comment
        .commentAudit(id, _this.status)
        .then((res) => {
          console.log(res)
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$emit('callbackMethod')
            _this.$message.success(res.errorMsg)
            _this.GetList()
            console.log(_this.GetList())
          } else {
            _this.isloading = false
            _this.$message.error(res.errorMsg)
          }
        })
        .catch(() => {
          _this.isloading = false
        })
    },
  },
}
</script>
<style>
.anticon-plus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.anticon-minus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: #1890ff !important;
  border: 1px solid #1890ff !important;
}
/* .ant-radio-disabled .ant-radio-inner {
  border: 1px solid #1890ff !important;
} */
</style>
<style scoped>
.ant-input[disabled] {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #1890ff;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
</style>